import {
    ORG_UNITS_LOAD_RESP,
    ORG_UNITS_LOAD_REQ,
    ORG_UNIT_ADD_REQ,
    ORG_UNIT_ADD_RESP,
    ORG_UNITS_EXPANDED,
    ORG_UNIT_TREE_SELECTED,
    ORG_UNIT_TREE_HIERARCHY_CHANGED,
    ORG_UNIT_HIERARCHY_CHANGED_REQ,
    ORG_UNIT_DELETE_REQ,
} from '../actionTypes';
import {apiCall} from '../api'
import { put, call , spawn} from  'redux-saga/effects';
import {actions as notifActions } from 'redux-notifications';
import {createNotification} from "../notificationProvider";
const { notifSend } = notifActions;

export const requestOrgUnits = () => ({
    type: ORG_UNITS_LOAD_REQ,
});

export const requestOrgUnitDelete = (orgUnitId) => ({
    type: ORG_UNIT_DELETE_REQ,
    orgUnitId: orgUnitId
});

export const expandedOrgUnitIds = (expandedOrgUnitIds) => ({
    type: ORG_UNITS_EXPANDED,
    expandedOrgUnitIds: expandedOrgUnitIds
});

export const orgUnitHierarchyChangedReq = (orgUnitHierarchy) => ({
    type: ORG_UNIT_HIERARCHY_CHANGED_REQ,
    orgUnitHierarchy: orgUnitHierarchy
});

export const orgUnitHierarchyChanged = (orgUnits) => ({
    type: ORG_UNIT_TREE_HIERARCHY_CHANGED,
    newHierarchy: orgUnits
});

export const requestAddOrgUnit = (orgUnit) => ({
    type: ORG_UNIT_ADD_REQ,
    orgUnit: orgUnit
});

export const orgUnitTreeSelected = (orgUnitId) => ({
    type: ORG_UNIT_TREE_SELECTED,
    selectedOrgUnit: orgUnitId
});

export const responseOrgUnits = (orgUnits) => ({
    type: ORG_UNITS_LOAD_RESP,
    orgUnits: orgUnits
});

export const responseAddOrgUnit = (addOrgOrgunit) => ({
    type: ORG_UNIT_ADD_RESP,
    addedOrgUnit: addOrgOrgunit
});

export function* loadOrgUnits() {
    try {
        let data = yield call(apiCall, '/orgUnit/getOrgUnits');
        yield put(responseOrgUnits(data.orgUnits));
    } catch (e) {
        console.log('Loading of users failed');
        yield put(responseOrgUnits([]));
    }
}


export function* addOrgUnit({orgUnit}) {
    try {
        let data = yield call(apiCall, '/orgUnit/addOrgUnit', {orgUnit});
        yield put(responseAddOrgUnit(data.orgUnit));
        yield put(notifSend(createNotification(data.msg)));
        yield spawn(loadOrgUnits);
    } catch (e) {
        yield put(responseAddOrgUnit());
    }
}

export function* deleteOrgUnit({orgUnitId}) {
    try {
        let data = yield call(apiCall, '/orgUnit/deleteOrgUnit', {orgUnitId});
        yield put(notifSend(createNotification(data.msg)));
        yield spawn(loadOrgUnits);
    } catch (e) {
    }
}

export function* editOrgUnitHierarchy({orgUnitHierarchy}) {
    try {
        let data = yield call(apiCall, '/orgUnit/editOrgUnitHierarchy', {orgUnitHierarchy});
        yield put(notifSend(createNotification(data.msg)));
        yield spawn(loadOrgUnits);
    } catch (e) {
        // yield put(requestOrgUnits());
    }
}


