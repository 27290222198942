const hasNumber = value => {
    return new RegExp(/[0-9]/).test(value);
};
const hasMixed = value => {
    return new RegExp(/[a-z]/).test(value) &&
        new RegExp(/[A-Z]/).test(value);
};
const hasSpecial = value => {
    return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
};

export const passwordStrength = value => {
    let errors = [];
    if (value.length < 8)
        errors.push('password.tooShort');
    if (!hasNumber(value))
        errors.push('password.noNumber');
    if (!hasSpecial(value))
        errors.push('password.noSpecial');
    if (!hasMixed(value))
        errors.push('password.noMixed');
    return errors;
};