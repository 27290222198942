export const createNotification = (message, dismissAfter = 4000) => {
  return {
    message: message.msg,
    level: translateTypeToLevel(message.type),
    dismissAfter: dismissAfter,
  };
};

export const createDefaultErrorNotification = () => {
  return createNotification({ msg: 'common.error', type: 'error' });
};

const translateTypeToLevel = type => {
  if (type === 'error') {
    return 'danger';
  } else {
    return 'primary';
  }
};
