import {
  ACCESS_ROLES_LOAD_REQ,
  ACCESS_ROLES_LOAD_RESP,
  ACCESS_ROLE_ADD_REQ,
  ACCESS_ROLE_ADD_RESP,
  ACCESS_ROLES_UPDATE_REQ,
  ACCESS_ROLES_UPDATE_RESP,
  ACCESS_ROLES_REPLACE_PERMISSION,
} from '../../actions/actionTypes';
let initialStateAccessRoles = { accessRoles: [], accessRolesLoading: false };

export const accessRolesReducer = (state = initialStateAccessRoles, action) => {
  switch (action.type) {
    case ACCESS_ROLES_LOAD_REQ:
      return {
        accessRoles: [],
        accessRolesLoading: true,
      };
    case ACCESS_ROLES_LOAD_RESP:
      return {
        accessRolesLoading: false,
        accessRoles: action.accessRoles,
      };
    case ACCESS_ROLES_REPLACE_PERMISSION:
      let newRoles = state.accessRoles.slice();
      let roleId = action.accessRoleId;
      newRoles.forEach(function(role) {
        if (role._id === roleId) {
          if (action.newPermissions) {
            role.editMode = true;
            role.permissions = action.newPermissions;
          }
        }
      });
      return {
        accessRoles: newRoles,
      };
    default:
      return state;
  }
};

let initialAddAccessRole = { addingAccessRole: false, addAccessRoleMsg: null };
export const addAccessRoleReducer = (state = initialAddAccessRole, action) => {
  switch (action.type) {
    case ACCESS_ROLE_ADD_REQ:
      return {
        addingAccessRole: true,
      };
    case ACCESS_ROLE_ADD_RESP:
      return {
        addAccessRoleMsg: action.msg,
        addingAccessRole: false,
      };
    default:
      return state;
  }
};

let initialAccessRolesUpdate = { accessRolesUpdating: false };
export const accessRolesUpdate = (state = initialAccessRolesUpdate, action) => {
  switch (action.type) {
    case ACCESS_ROLES_UPDATE_REQ:
      return {
        accessRolesUpdating: true,
      };
    case ACCESS_ROLES_UPDATE_RESP:
      return {
        accessRolesUpdating: false,
        msg: action.msg,
      };
    default:
      return state;
  }
};
