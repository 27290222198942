import {
    STATISTICS_PF_DATA_REQ, STATISTICS_PF_DATA_RESP,
} from '../actionTypes';
import {apiCall} from '../api'
import { put, call } from  'redux-saga/effects';
import {createDefaultErrorNotification} from '../notificationProvider';
import {actions as notifActions } from 'redux-notifications';
const { notifSend } = notifActions;

export const requestPensionFundDataStats = () => ({
    type: STATISTICS_PF_DATA_REQ,
});
export const responsePensionFundDataStats = (data) => ({
    type: STATISTICS_PF_DATA_RESP,
    data
});

export function* loadPensionFundDataStats() {
    try {
        let data = yield call(apiCall, '/statistics/pensionDataStatsByPeriods');
        yield put(responsePensionFundDataStats(data.data));
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}
