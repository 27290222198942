import {
  PERMISSIONS_LOAD_REQ,
  PERMISSIONS_LOAD_RESP,
  PERMISSIONS_ADMIN_LOAD_REQ,
  PERMISSIONS_ADMIN_LOAD_RESP,
} from '../../actions/actionTypes';
let initialState = {
  permissions: [],
  permissionsLoading: false,
  adminPermissions: [],
  adminPermissionsLoading: false
};

export const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERMISSIONS_LOAD_REQ:
      return {
        ...state,
        permissions: [],
        permissionsLoading: true,
      };
    case PERMISSIONS_LOAD_RESP:
      return {
        ...state,
        permissionsLoading: false,
        permissions: action.permissions,
      };
    case PERMISSIONS_ADMIN_LOAD_REQ:
      return {
        ...state,
        adminPermissions: [],
        adminPermissionsLoading: true,
      };
    case PERMISSIONS_ADMIN_LOAD_RESP:
      return {
        ...state,
        adminPermissionsLoading: false,
        adminPermissions: action.permissions,
      };
    default:
      return state;
  }
};
