import {
    ADMIN_LOGS_REQ,
    ADMIN_LOGS_RESP
} from "../../actions/actionTypes";

let initialState = {adminLogsLoading: false, adminLogs: []};

export const adminLogReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_LOGS_REQ:
            return {
                adminLogsLoading: true,
                adminLogs: []
            };
        case ADMIN_LOGS_RESP:
            return {
                adminLogsLoading: false,
                adminLogs: action.adminLogs
            };
        default:
            return state;
    }
};
