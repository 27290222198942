export const LOGIN_REQUEST = 'LOGIN';
export const LOGIN_REFRESH_REQUEST = 'LOGIN_REFRESH_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGGED_OUT = 'LOGGED_OUT';
export const REQUEST_PASSWORD_RESET_REQ = 'REQUEST_PASSWORD_RESET_REQ';
export const REQUEST_PASSWORD_RESET_FAILED = 'REQUEST_PASSWORD_RESET_FAILED';
export const RESET_FORGOTTEN_PASSWORD_REQ = 'RESET_FORGOTTEN_PASSWORD_REQ';
export const CHECK_VERIFICATION_TOKEN_REQ = 'CHECK_VERIFICATION_TOKEN_REQ';
export const CHECK_VERIFICATION_TOKEN_SUCCESS = 'CHECK_VERIFICATION_TOKEN_SUCCESS';
export const CHECK_VERIFICATION_TOKEN_FAILED = 'CHECK_VERIFICATION_TOKEN_FAILED';
export const OPEN_CLOSE = 'OPEN_CLOSE';
export const USER_CONTEXT_ADD = 'USER_CONTEXT_ADD';
export const INITIALIZE_ROUTES = 'INITIALIZE_ROUTES';
export const USER_CONTEXT_REMOVED = 'USER_CONTEXT_REMOVED';
export const USER_PASSWORD_UPDATE_REQ = 'USER_PASSWORD_UPDATE_REQ';
export const USER_PASSWORD_UPDATING = 'USER_PASSWORD_UPDATING';
export const USER_DETAIL_UPDATE_REQ = 'USER_DETAIL_UPDATE_REQ';
export const USER_DETAIL_UPDATE_RESP = 'USER_DETAIL_UPDATE_RESP';

export const USERS_CREATE_REQ = 'USERS_CREATE_REQ';
export const USERS_CREATE_RESP = 'USERS_CREATE_RESP';

export const USERS_OPEN_DIALOG = 'USERS_OPEN_DIALOG';
export const USERS_CLOSE_DIALOG = 'USERS_CLOSE_DIALOG';

export const USERS_UPDATE_REQ = 'USERS_UPDATE_REQ';
export const USERS_UPDATE_RESP = 'USERS_UPDATE_RESP';

export const USERS_LOAD_FOR_PROVIDER_REQ = 'USERS_LOAD_FOR_PROVIDER_REQ';
export const USERS_LOAD_FOR_PROVIDER_RESP = 'USERS_LOAD_FOR_PROVIDER_RESP';

export const USERS_MOVE_ALL_ASSIGNED = 'USERS_MOVE_ALL_ASSIGNED';
export const USERS_MOVE_ALL_UNASSIGNED = 'USERS_MOVE_ALL_UNASSIGNED';

export const USERS_MOVE_SELECTED_ASSIGNED = 'USERS_MOVE_SELECTED_ASSIGNED';
export const USERS_MOVE_SELECTED_UNASSIGNED = 'USERS_MOVE_SELECTED_UNASSIGNED';

export const USERS_UPDATE_PROVIDER_REQ = 'USERS_UPDATE_PROVIDER_REQ';

export const USERS_DELETE_REQ = 'USERS_DELETE_REQ';

export const ADMIN_LOGS_REQ = 'ADMIN_LOGS_REQ';
export const ADMIN_LOGS_RESP = 'ADMIN_LOGS_RESP';

//ROUTES
export const ROUTES_REQUESTED = 'ROUTES_REQUESTED';
export const ROUTES_ADD_NEW_ROUTE = 'ROUTES_ADD_NEW_ROUTE';
export const ROUTES_RECEIVED = 'ROUTES_RECEIVED';
export const ROUTES_REQUEST_FAILED = 'ROUTES_REQUEST_FAILED';
export const ROUTES_DELETE_REQ = 'ROUTES_DELETE_REQ';
export const ROUTES_DELETE_RESP = 'ROUTES_DELETE_RESP';
export const ROUTES_ADD_REQ = 'ROUTES_ADD_REQ';
export const ROUTES_ADD_RESP = 'ROUTES_ADD_RESP';

//STATISTICS
export const STATISTICS_PF_DATA_REQ = 'STATISTICS_PF_DATA_REQ';
export const STATISTICS_PF_DATA_RESP = 'STATISTICS_PF_DATA_RESP';

//PERMISSION
export const PERMISSIONS_LOAD_REQ = 'PERMISSIONS_LOAD_REQ';
export const PERMISSIONS_LOAD_RESP = 'PERMISSIONS_LOAD_RESP';

export const PERMISSIONS_ADMIN_LOAD_REQ = 'PERMISSIONS_ADMIN_LOAD_REQ';
export const PERMISSIONS_ADMIN_LOAD_RESP = 'PERMISSIONS_ADMIN_LOAD_RESP';
export const PERMISSIONS_ADMIN_UPDATE = 'PERMISSIONS_ADMIN_UPDATE';

export const PERMISSIONS_ADMIN_UPDATE_REQ = 'PERMISSIONS_ADMIN_UPDATE_REQ';


//ACCESS ROLES
export const ACCESS_ROLES_LOAD_REQ = 'ACCESS_ROLES_LOAD_REQ';
export const ACCESS_ROLES_LOAD_RESP = 'ACCESS_ROLES_LOAD_RESP';
export const ACCESS_ROLE_ADD_REQ = 'ACCESS_ROLE_ADD_REQ';
export const ACCESS_ROLE_ADD_RESP = 'ACCESS_ROLE_ADD_RESP';

export const ACCESS_ROLE_REMOVE_REQ = 'ACCESS_ROLE_REMOVE_REQ';
export const ACCESS_ROLE_REMOVE_RESP = 'ACCESS_ROLE_REMOVE_RESP';

export const ACCESS_ROLES_UPDATE_REQ = 'ACCESS_ROLES_UPDATE_REQ';
export const ACCESS_ROLES_UPDATE_RESP = 'ACCESS_ROLES_UPDATE_RESP';

export const ACCESS_ROLES_REPLACE_PERMISSION = 'ACCESS_ROLES_REPLACE_PERMISSION';

export const COMPANY_LOAD_REQ = 'COMPANY_LOAD_REQ';
export const COMPANY_LOAD_RESP = 'COMPANY_LOAD_RESP';

export const TENANT_LOAD_REQ = 'TENANT_LOAD_REQ';
export const TENANT_LOAD_RESP = 'TENANT_LOAD_RESP';

export const TENANT_CREATE_NEW_REQ = 'TENANT_CREATE_NEW_REQ';
export const TENANT_CREATE_NEW_RESP = 'TENANT_CREATE_NEW_RESP';

export const USERS_LOAD_REQ = 'USERS_LOAD_REQ';
export const USERS_LOAD_RESP = 'USERS_LOAD_RESP';

//ORG UNITS
export const ORG_UNITS_LOAD_REQ = 'ORG_UNITS_LOAD_REQ';
export const ORG_UNITS_EXPANDED = 'ORG_UNITS_EXPANDED';
export const ORG_UNITS_LOAD_RESP = 'ORG_UNITS_LOAD_RESP';

//PROVIDERS
export const PROVIDERS_LOAD_REQ = 'PROVIDERS_LOAD_REQ';
export const PROVIDERS_ACTIVE_LOAD_REQ = 'PROVIDERS_ACTIVE_LOAD_REQ';
export const PROVIDERS_LOAD_RESP = 'PROVIDERS_LOAD_RESP';
export const PROVIDERS_ACTIVE_LOAD_RESP = 'PROVIDERS_ACTIVE_LOAD_RESP';
export const PROVIDERS_ROW_EXPANDED = 'PROVIDERS_ROW_EXPANDED';
export const PROVIDER_ADD_REQ = 'PROVIDERS_ADD_REQ';
export const PROVIDER_ADD_RESP = 'PROVIDERS_ADD_RESP';
export const PROVIDER_DELETE_REQ = 'PROVIDER_DELETE_REQ ';
export const PROVIDER_DELETE_RESP = 'PROVIDER_DELETE_RESP ';
export const PROVIDER_EDIT_REQ = 'PROVIDER_EDIT_REQ';
export const PROVIDER_EDIT_RESP = 'PROVIDER_EDIT_RESP';

//Pension fund
export const PENSION_FUND_DATA_ACCORDION_UPDATE_REQ = 'PENSION_FUND_DATA_ACCORDION_UPDATE_REQ';
export const PENSION_FUND_DATA_ACCORDION_UPDATE_RESP = 'PENSION_FUND_DATA_ACCORDION_UPDATE_RESP';
export const PENSION_FUND_DATA_LOAD_REQ = 'PENSION_FUND_DATA_LOAD_REQ';
export const PENSION_FUND_VALIDATION_DATA_LOAD_REQ = 'PENSION_FUND_VALIDATION_DATA_LOAD_REQ';
export const PENSION_FUND_VALIDATION_DATA_LOAD_RESP = 'PENSION_FUND_VALIDATION_DATA_LOAD_RESP';
export const PENSION_FUND_VALIDATION_DATA_UPDATE_REQ = 'PENSION_FUND_VALIDATION_DATA_UPDATE_REQ';
export const PENSION_FUND_VALIDATION_DATA_UPDATE_RESP = 'PENSION_FUND_VALIDATION_DATA_UPDATE_RESP';
export const PENSION_FUND_DATA_LOAD_RESP = 'PENSION_FUND_DATA_LOAD_RESP';
export const PENSION_FUND_PROVIDER_DATA_LOAD_RESP = 'PENSION_FUND_PROVIDER_DATA_LOAD_RESP';
export const PENSION_FUND_PERIOD_LOAD_REQ = 'PENSION_FUND_PERIOD_LOAD_REQ';
export const PENSION_FUND_PERIOD_LOAD_RESP = 'PENSION_FUND_PERIOD_LOAD_RESP';
export const PENSION_FUND_PERIOD_STATUS_LOAD_REQ = 'PENSION_FUND_PERIOD_STATUS_LOAD_REQ';
export const PENSION_FUND_PERIOD_STATUS_LOAD_RESP = 'PENSION_FUND_PERIOD_STATUS_LOAD_RESP';
export const PENSION_FUND_BY_ID_REQ = 'PENSION_FUND_BY_ID_REQ';
export const PENSION_FUND_BY_ID_RESP = 'PENSION_FUND_BY_ID_RESP';
export const PENSION_FUND_EXPAND_ACCORDION = 'PENSION_FUND_EXPAND_ACCORDION';
export const PENSION_FUND_RESET_ACCORDION = 'PENSION_FUND_RESET_ACCORDION';
export const PENSION_FUND_CHANGE_VALIDATION = 'PENSION_FUND_CHANGE_VALIDATION';
export const PENSION_FUND_SEARCH_REQ = 'PENSION_FUND_SEARCH_REQ';
export const PENSION_FUND_SEARCH_RESP = 'PENSION_FUND_SEARCH_RESP';

export const PENSION_FUND_CHANGE_STEP = 'PENSION_FUND_CHANGE_STEP';
export const PENSION_FUND_GOTO_INPUT = 'PENSION_FUND_GOTO_INPUT';

export const PENSION_FUND_INPUT_COMPLETE = 'PENSION_FUND_INPUT_COMPLETE';

export const ORG_UNIT_ADD_REQ = 'ORG_UNIT_ADD_REQ';
export const ORG_UNIT_ADD_RESP = 'ORG_UNIT_ADD_RESP';

export const ORG_UNIT_TREE_SELECTED = 'ORG_UNIT_TREE_SELECTED';
export const ORG_UNIT_TREE_HIERARCHY_CHANGED = 'ORG_UNIT_TREE_HIERARCHY_CHANGED';
export const ORG_UNIT_HIERARCHY_CHANGED_REQ = 'ORG_UNIT_HIERARCHY_CHANGED_REQ';

export const ORG_UNIT_DELETE_REQ = 'ORG_UNIT_DELETE_REQ';
export const ORG_UNIT_DELETE_RESP = 'ORG_UNIT_DELETE_RESP';

//Reports
export const REPORT_CHANGE_REPORT_PERIOD = 'REPORT_CHANGE_REPORT_PERIOD';
export const REPORT_CHANGE_DISPLAYED_ROWS = 'REPORT_CHANGE_DISPLAYED_ROWS';
export const REPORT_CHANGE_CONVERSIONRATE_RANKING = 'REPORT_CHANGE_CONVERSIONRATE_RANKING';
export const REPORT_SET_FIXED_PROVIDER_LIST = 'REPORT_SET_FIXED_PROVIDER_LIST';
export const REPORT_SET_ANONYMISE_PROVIDERS = 'REPORT_SET_ANONYMISE_PROVIDERS';
