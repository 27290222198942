import {
    ROUTES_REQUESTED,
    ROUTES_REQUEST_FAILED,
    ROUTES_RECEIVED,
    ROUTES_DELETE_REQ,
    ROUTES_DELETE_RESP,
    ROUTES_ADD_REQ,
    ROUTES_ADD_RESP,
    ROUTES_ADD_NEW_ROUTE
} from "../../actions/actionTypes";
let initialState = {routesLoading: false, routes: []};

export const routesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ROUTES_REQUESTED: {
            return {
                routesLoading:true,
                routeDeleting:false,
                routes: []
            };
        }
        case ROUTES_ADD_NEW_ROUTE: {
            let filtered = state.routes.filter(route => route._id !== action.newRoute._id);
            let newRoutes = [...filtered, action.newRoute];
            return {
                ...state,
                routesLoading:true,
                routes: newRoutes
            };
        }
        case ROUTES_REQUEST_FAILED: {
            return {
                routesLoading:false,
                error: action.error,
                routes: []
            };
        }
        case ROUTES_RECEIVED: {
            return {
                routesLoading:false,
                routes: action.routes
            };
        }
        case ROUTES_DELETE_RESP: {
            if (action.routeId) {
                let newRoutes = state.routes.filter(route => route._id !== action.routeId);
                newRoutes.forEach(route => route.views = route.views.filter(subRoute => subRoute._id !== action.routeId));
                return {
                    routeDeleting:false,
                    msg: action.msg,
                    routes: newRoutes
                };
            }
            return state;
        }
        case ROUTES_DELETE_REQ: {
            return {
                ...state,
                routeDeleting:true,
            };
        }
        case ROUTES_ADD_REQ: {
            return {
                ...state,
                addingRoute:true,
            };
        }
        case ROUTES_ADD_RESP: {
            return {
                ...state,
                addingRoute:false,
                msg: action.msg,
                error: action.error
            };
        }
        default:
            return state;
    }
};

