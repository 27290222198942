import {USER_CONTEXT_ADD, USER_CONTEXT_REMOVED} from "../actions/actionTypes";

let initialContext = {context : {}, translation: {}};
const contextProvider = (state = initialContext, action) => {
    switch (action.type) {
        case USER_CONTEXT_ADD:
            return { 
                ...state, 
                ...action.context 
            };
        case USER_CONTEXT_REMOVED:
            return initialContext;
        default:
            return state;
    }
};

export default contextProvider;
