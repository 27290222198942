import {
  USERS_LOAD_RESP,
  USERS_LOAD_REQ,
  USERS_UPDATE_RESP,
  USERS_DELETE_REQ,
  USERS_OPEN_DIALOG,
  USERS_CLOSE_DIALOG,
  USERS_LOAD_FOR_PROVIDER_RESP,
  USERS_MOVE_ALL_ASSIGNED,
  USERS_MOVE_ALL_UNASSIGNED,
  USERS_LOAD_FOR_PROVIDER_REQ,
  USERS_MOVE_SELECTED_UNASSIGNED,
  USERS_MOVE_SELECTED_ASSIGNED,
  USER_DETAIL_UPDATE_REQ,
  USER_DETAIL_UPDATE_RESP
} from '../../actions/actionTypes';

let initialState = {
  usersLoading: false,
  users: [],
  providerUsers: [],
  openNewUserDialog: false,
  isPensionFundUser: false,
  transferList: {
    providerId: '',
    unassignedUsers: [],
    assignedUsers: []
  },
  userUpdateLoading: false,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_OPEN_DIALOG:
      return {
        ...state,
        openNewUserDialog: true,
        isPensionFundUser: action.pensionFundUser,
      };
    case USERS_CLOSE_DIALOG:
      return {
        ...state,
        openNewUserDialog: false,
      };
    case USERS_LOAD_REQ:
      return {
        ...state,
        users: [],
        usersLoading: true,
      };
    case USERS_LOAD_RESP:
      return {
        ...state,
        users: action.users.filter(user => !user.providerOnly),
        providerUsers: action.users.filter(user => user.providerOnly),
        usersLoading: false,
      };
    case USERS_UPDATE_RESP:
      let updatedIds = action.updatedUsers.map(user => user._id);
      let newUsers = state.users.filter(user => !updatedIds.includes(user._id));
      let newProviderUsers = state.providerUsers.filter(user => !updatedIds.includes(user._id));
      return {
        ...state,
        users: [...newUsers, ...action.updatedUsers.filter(user => !user.providerOnly)],
        providerUsers: [...newProviderUsers, ...action.updatedUsers.filter(user => user.providerOnly)],
        usersLoading: false,
      };
    case USERS_LOAD_FOR_PROVIDER_RESP:
      return {
        ...state,
        transferList: {
          ...state.transferList,
          unassignedUsers: action.users.filter(user => !user.provider),
          assignedUsers: action.users.filter(user => user.provider)
        }
      };
    case USERS_MOVE_ALL_ASSIGNED:
      return {
        ...state,
        transferList: {
          ...state.transferList,
          unassignedUsers: [...state.transferList.unassignedUsers, ...state.transferList.assignedUsers],
          assignedUsers: []
        }
      };
    case USERS_MOVE_ALL_UNASSIGNED:
      return {
        ...state,
        transferList: {
          ...state.transferList,
          unassignedUsers: [],
          assignedUsers: [...state.transferList.unassignedUsers, ...state.transferList.assignedUsers],
        }
      };
    case USERS_MOVE_SELECTED_UNASSIGNED:
      return {
        ...state,
        transferList: {
          ...state.transferList,
          unassignedUsers: [...state.transferList.unassignedUsers.filter(user => !action.userIds.includes(user._id))],
          assignedUsers: [...state.transferList.assignedUsers, ...state.transferList.unassignedUsers.filter(user => action.userIds.includes(user._id))],
        }
      };
    case USERS_MOVE_SELECTED_ASSIGNED:
      return {
        ...state,
        transferList: {
          ...state.transferList,
          assignedUsers: [...state.transferList.assignedUsers.filter(user => !action.userIds.includes(user._id))],
          unassignedUsers: [...state.transferList.unassignedUsers, ...state.transferList.assignedUsers.filter(user => action.userIds.includes(user._id))],
        }
      };
    case USERS_LOAD_FOR_PROVIDER_REQ:
      return {
        ...state,
        transferList: {
          ...state.transferList,
          providerId: action.providerId
        }
      };
    case USER_DETAIL_UPDATE_REQ:
      return {
        ...state,
        userUpdateLoading: true,
      };
    case USER_DETAIL_UPDATE_RESP:
      return {
        ...state,
        userUpdateLoading: false,
      };

    case USERS_DELETE_REQ:
      let nonDeletedUsers = state.users.filter(user => !action.usersToDelete.includes(user._id));
      return {
        ...state,
        users: [...nonDeletedUsers],
        usersLoading: true,
      };
    default:
      return state;
  }
};

export default usersReducer;
