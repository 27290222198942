import {
    PERMISSIONS_LOAD_REQ,
    PERMISSIONS_LOAD_RESP,
    PERMISSIONS_ADMIN_LOAD_REQ,
    PERMISSIONS_ADMIN_LOAD_RESP,
    PERMISSIONS_ADMIN_UPDATE_REQ,
    PERMISSIONS_ADMIN_UPDATE
} from '../actionTypes';
import {loadTenants} from "./company";
import {put, call, spawn} from 'redux-saga/effects';
import {apiCall} from '../api';
import {createNotification} from '../notificationProvider';
import {actions as notifActions } from 'redux-notifications';
const { notifSend } = notifActions;

export function* loadPermissions() {
    try {
        let data = yield call(apiCall, '/accessRole/getPermissions');
        yield put(responsePermissions(data.permissions));
    } catch (e) {
        console.log('Loading of permissions failed');
        yield put(responsePermissions([]));
    }
};


export function* loadPermissionsForAdmin() {
    try {
        let data = yield call(apiCall, '/accessRole/getPermissionsForAdmin');
        yield put(responseAdminPermissions(data.permissions));
    } catch (e) {
        console.log('Loading of permissions failed');
        yield put(responseAdminPermissions([]));
    }
}


export function* updateAdminPermissions({permissionsToUpdate}) {
    try {
        let data = yield call(apiCall, '/accessRole/updatePermissions', {permissionsToUpdate});
        yield put(notifSend(createNotification(data.msg)));
        yield spawn(loadTenants);
    } catch (e) {
        console.log(e);
    }
}



export const requestPermissionsUpdate = (permissions) => ({
    type: PERMISSIONS_ADMIN_UPDATE_REQ,
    permissionsToUpdate: permissions
});

export const requestPermissions = () => ({
    type: PERMISSIONS_LOAD_REQ,
});

export const requestAdminPermissions = () => ({
    type: PERMISSIONS_ADMIN_LOAD_REQ,
});

export const updateAdminPermission = (tenantId, permissions) => ({
    type: PERMISSIONS_ADMIN_UPDATE,
    newPermissions: permissions,
    tenantId: tenantId
});

export const responsePermissions = (permissions) => ({
    type: PERMISSIONS_LOAD_RESP,
    permissions: permissions
});

export const responseAdminPermissions = (permissions) => ({
    type: PERMISSIONS_ADMIN_LOAD_RESP,
    permissions: permissions
});
