import {
    PENSION_FUND_CHANGE_STEP,
    PENSION_FUND_DATA_LOAD_RESP,
    PENSION_FUND_DATA_LOAD_REQ,
    PENSION_FUND_PROVIDER_DATA_LOAD_RESP,
    PENSION_FUND_GOTO_INPUT,
    PENSION_FUND_PERIOD_LOAD_REQ,
    PENSION_FUND_PERIOD_LOAD_RESP,
    PENSION_FUND_DATA_ACCORDION_UPDATE_REQ,
    PENSION_FUND_EXPAND_ACCORDION,
    PENSION_FUND_RESET_ACCORDION,
    PENSION_FUND_VALIDATION_DATA_LOAD_REQ,
    PENSION_FUND_VALIDATION_DATA_LOAD_RESP,
    PENSION_FUND_CHANGE_VALIDATION,
    PENSION_FUND_VALIDATION_DATA_UPDATE_REQ,
    PENSION_FUND_INPUT_COMPLETE,
    PENSION_FUND_BY_ID_REQ,
    PENSION_FUND_BY_ID_RESP,
    PENSION_FUND_PERIOD_STATUS_LOAD_RESP,
    PENSION_FUND_PERIOD_STATUS_LOAD_REQ, PENSION_FUND_SEARCH_REQ, PENSION_FUND_SEARCH_RESP
} from '../actionTypes';
import {apiCall} from '../api'
import {put, call, spawn} from 'redux-saga/effects';
import {INPUT_DATA_STATUS, PENSION_STEP_COMPLETE_ID, PENSION_STEP_INPUT_ID} from "../../variables/constants";
import {createDefaultErrorNotification, createNotification} from "../notificationProvider";
import {actions as notifActions} from 'redux-notifications';

const {notifSend} = notifActions;

export const requestPensionFundValidation = () => ({
    type: PENSION_FUND_VALIDATION_DATA_LOAD_REQ,
});

export const responsePensionFundValidation = (pensionFundValidationData) => ({
    type: PENSION_FUND_VALIDATION_DATA_LOAD_RESP,
    pensionFundValidationData
});

export const updateAccordionData = (pensionFundDataId, values) => ({
    type: PENSION_FUND_DATA_ACCORDION_UPDATE_REQ,
    pensionFundDataId,
    values
});

export const changePensionFundValidation = (section:string,field: string, value:string) => ({
    type: PENSION_FUND_CHANGE_VALIDATION,
    section,
    field,
    value
});

export const navigateToStep = (stepId) => ({
    type: PENSION_FUND_CHANGE_STEP,
    stepId
});

export const requestPensionFundById = (pensionFundDataId) => ({
    type: PENSION_FUND_BY_ID_REQ,
    pensionFundDataId
});

export const responsePensionFundById = (pensionFundData) => ({
    type: PENSION_FUND_BY_ID_RESP,
    pensionFundData
});

export const updatePensionValidationData = (pensionValidationId, values) => ({
    type: PENSION_FUND_VALIDATION_DATA_UPDATE_REQ,
    pensionValidationId,
    values
});

export const goToInputPensionData = (providerId, period) => ({
    type: PENSION_FUND_GOTO_INPUT,
    providerId,
    period
});

export const complete = (pensionFundDataId) => ({
    type: PENSION_FUND_INPUT_COMPLETE,
    pensionFundDataId,
});

export const changePensionFundAccordion = (accordion) => ({
    type: PENSION_FUND_EXPAND_ACCORDION,
    accordion
});

export const resetAccordions = () => ({
    type: PENSION_FUND_RESET_ACCORDION,
});

export const requestPensionFundPeriods = () => ({
    type: PENSION_FUND_PERIOD_LOAD_REQ
});

export const requestPensionFundSearch = (searchCriteria) => ({
    type: PENSION_FUND_SEARCH_REQ,
    searchCriteria
});

export const responsePensionFundSearch = (providerList) => ({
    type: PENSION_FUND_SEARCH_RESP,
    providerList
});

export const requestPensionFundPeriodsStatus = (providerId) => ({
    type: PENSION_FUND_PERIOD_STATUS_LOAD_REQ,
    providerId
});

export const responsePensionFundDataProvider = (pensionFundDataProvider, section) => ({
    type: PENSION_FUND_PROVIDER_DATA_LOAD_RESP,
    pensionFundDataProvider,
    section
});

export const requestPensionFundData = (period) => ({
    type: PENSION_FUND_DATA_LOAD_REQ,
    period
});

export const responsePensionFundData = (pensionFundData) => ({
    type: PENSION_FUND_DATA_LOAD_RESP,
    pensionFundData
});

export const responsePensionFundPeriods = (pensionFundPeriods) => ({
    type: PENSION_FUND_PERIOD_LOAD_RESP,
    pensionFundPeriods
});

export const responsePensionFundPeriodsStatus = (pensionFundPeriodsWithStatus) => ({
    type: PENSION_FUND_PERIOD_STATUS_LOAD_RESP,
    pensionFundPeriodsWithStatus
});

export function* forwardToInputPensionData({providerId, period}) {
    try {
        const path = providerId ? 'loadPensionFundDataForProvider' : 'loadUserProviderPensionFundData';
        let data = yield call(apiCall, '/pensionFund/' + path, {providerId, period});
        yield put(responsePensionFundDataProvider(data));
        if ([INPUT_DATA_STATUS.IN_PROGRESS, INPUT_DATA_STATUS.NOT_STARTED].includes(data.status)) {
            yield put(navigateToStep(PENSION_STEP_INPUT_ID));
        } else if (data.status === INPUT_DATA_STATUS.COMPLETED) {
            yield put(requestPensionFundById(data._id));
            yield put(navigateToStep(PENSION_STEP_COMPLETE_ID));
        }
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* updatePensionFundDataSection({pensionFundDataId, values, section}) {
    try {
        let data = yield call(apiCall, '/pensionFund/updatePensionFundDataSection', {pensionFundDataId, values});
        yield put(responsePensionFundDataProvider(data, section));
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* loadPensionPeriods() {
    try {
        let data = yield call(apiCall, '/pensionFund/loadPensionFundPeriods');
        yield put(responsePensionFundPeriods(data));
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* loadPensionPeriodsStatus({providerId}) {
    try {
        let data = yield call(apiCall, '/pensionFund/loadPensionFundPeriodsStatus', {providerId});
        yield put(responsePensionFundPeriodsStatus(data));
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* loadPensionFundData({period}) {
    try {
        let data = yield call(apiCall, '/pensionFund/loadPensionFundData',{period});
        yield put(responsePensionFundData(data.pensionFundData));
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* loadPensionFundValidations() {
    try {
        let data = yield call(apiCall, '/pensionFund/loadPensionFundValidation');
        yield put(responsePensionFundValidation(data));
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* updatePensionFundValidations({pensionValidationId, values}) {
    try {
        let data = yield call(apiCall, '/pensionFund/updatePensionValidation', {pensionValidationId, values});
        yield put(notifSend(createNotification(data.msg)));
        yield spawn(loadPensionFundValidations);
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* loadPensionFundById({pensionFundDataId}) {
    try {
        let data = yield call(apiCall, '/pensionFund/loadPensionFundById', {pensionFundDataId});
        yield put(responsePensionFundById(data.pensionFundData))
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* pensionFundSearchMask({searchCriteria}) {
    try {
        let data = yield call(apiCall, '/report/searchMask', searchCriteria);
        yield put(responsePensionFundSearch(data.providerList))
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* completePensionFundData({pensionFundDataId}) {
    try {
        let data = yield call(apiCall, '/pensionFund/completeDataInput', {pensionFundDataId});
        yield put(requestPensionFundById(pensionFundDataId));
        yield put(navigateToStep(PENSION_STEP_COMPLETE_ID));
        yield put(notifSend(createNotification(data.msg)));
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}



