import {
    ADMIN_LOGS_REQ,
    ADMIN_LOGS_RESP
} from '../actionTypes';

import {put, call} from 'redux-saga/effects';
import {apiCall} from '../api';

export function* loadAdminLogs() {
    try {
        let data = yield call(apiCall, '/adminLogs/loadAllAdminLogs');
        yield put(adminLogsResponse(data.adminLogs));
    } catch (e) {
    }
}

export const adminLogsRequest = () => ({
    type: ADMIN_LOGS_REQ
});

export const adminLogsResponse = (adminLogs) => ({
    type: ADMIN_LOGS_RESP,
    adminLogs: adminLogs
});
