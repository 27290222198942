import {
    USERS_LOAD_REQ,
    USERS_LOAD_RESP,
    USERS_UPDATE_REQ,
    USERS_UPDATE_RESP,
    USERS_DELETE_REQ,
    USERS_CREATE_REQ,
    USERS_OPEN_DIALOG,
    USERS_CLOSE_DIALOG,
    USERS_LOAD_FOR_PROVIDER_REQ,
    USERS_LOAD_FOR_PROVIDER_RESP,
    USERS_MOVE_ALL_UNASSIGNED,
    USERS_MOVE_ALL_ASSIGNED, USERS_UPDATE_PROVIDER_REQ, USERS_MOVE_SELECTED_ASSIGNED, USERS_MOVE_SELECTED_UNASSIGNED
} from '../actionTypes';
import {apiCall} from '../api'
import { put, call, fork } from  'redux-saga/effects';
import {createDefaultErrorNotification, createNotification} from '../notificationProvider';
import {actions as notifActions } from 'redux-notifications';
const { notifSend } = notifActions;

export const requestLoadUsers = () => ({
    type: USERS_LOAD_REQ,
});


export const requestCreateUser = (user) => ({
    type: USERS_CREATE_REQ,
    user
});

export const openNewUserDialog = (pensionFundUser: boolean) => ({
    type: USERS_OPEN_DIALOG,
    pensionFundUser
});

export const requestLoadUsersForProvider = (providerId) =>({
   type: USERS_LOAD_FOR_PROVIDER_REQ,
   providerId
});

export const responseLoadUsersForProvider = (users) =>({
   type: USERS_LOAD_FOR_PROVIDER_RESP,
   users
});

export const moveAllUnassigned = () => ({
    type: USERS_MOVE_ALL_UNASSIGNED
});

export const moveAllAssigned = () => ({
    type: USERS_MOVE_ALL_ASSIGNED
});

export const moveSelectedAssigned = (userIds) => ({
    type: USERS_MOVE_SELECTED_ASSIGNED,
    userIds
});

export const moveSelectedUnassigned = (userIds) => ({
    type: USERS_MOVE_SELECTED_UNASSIGNED,
    userIds
});

export const requestUpdateUsersProvider = (providerId, assignedUserIds, unassignedUserIds) => ({
    type: USERS_UPDATE_PROVIDER_REQ,
    providerId,
    assignedUserIds,
    unassignedUserIds
});

export const closeNewUserDialog = () => ({
    type: USERS_CLOSE_DIALOG,
});

export const responseLoadUsers = (users) => ({
    type: USERS_LOAD_RESP,
    users: users
});

export const usersUpdateReq = (users) => ({
    type: USERS_UPDATE_REQ,
    usersToUpdate: users
});

export const usersDeleteReq = (users) => ({
    type: USERS_DELETE_REQ,
    usersToDelete: users
});

export const usersUpdateResp = ( updatedUsers) => ({
    type: USERS_UPDATE_RESP,
    updatedUsers: updatedUsers
});


export function* updateUsers({usersToUpdate}) {
    try {
        let data = yield call(apiCall, '/users/updateUsers', {usersToUpdate});
        yield put(usersUpdateResp(data.updatedUsers));
        yield put(notifSend(createNotification(data.msg)));
    } catch(error) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* createUser({user}) {
    try {
        let data = yield call(apiCall, '/users/addNewUser', {newUser: user});
        yield fork(loadUsers);
        yield put(closeNewUserDialog());
        yield put(notifSend(createNotification(data.msg)));
    } catch(error) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}


export function* deleteUsers({usersToDelete}) {
    try {
        let data = yield call(apiCall, '/users/deleteUsers', {usersToDelete});
        yield fork(loadUsers);
        yield put(notifSend(createNotification(data.msg)));
    } catch(error) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* loadUsers() {
    try {
        let data = yield call(apiCall, '/users/getAllUsers');
        yield put(responseLoadUsers(data.users));
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* loadUsersForProvider({providerId}) {
    try {
        let data = yield call(apiCall, '/users/loadUsersForProvider', {providerId});
        yield put(responseLoadUsersForProvider(data.users));
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}

export function* updateUsersProvider({providerId, assignedUserIds, unassignedUserIds}) {
    try {
        let data = yield call(apiCall, '/users/updateUsersProvider', {providerId, assignedUserIds, unassignedUserIds});
        yield put(notifSend(createNotification(data.msg)));
    } catch (e) {
        yield put(notifSend(createDefaultErrorNotification()));
    }
}
