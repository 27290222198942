import {takeLatest} from 'redux-saga/effects';
import { updateUserDetails, updateUserPassword} from './userActions';
import {
    loadOrgUnits,
    addOrgUnit,
    editOrgUnitHierarchy,
    deleteOrgUnit,
} from './admin/orgUnitsAction';
import {
    loadAllProviders,
    deleteProvider,
    addProvider,
    editProvider, loadActiveProviders,
} from './admin/providers';
import {
    loadPensionPeriods,
    forwardToInputPensionData,
    updatePensionFundDataSection,
    loadPensionFundValidations,
    updatePensionFundValidations,
    completePensionFundData,
    loadPensionFundData,
    loadPensionFundById, loadPensionPeriodsStatus, pensionFundSearchMask,
} from './admin/pensionFundAction';
import { doLoginSaga, doRefreshLogin, doRequestPasswordReset, doResetForgottenPassword, doCheckVerificationToken} from './index';
import {requestRoutes, deleteRouteApi, addRoute} from './admin/routes';
import {
    loadPermissions,
    loadPermissionsForAdmin,
    updateAdminPermissions,
} from './admin/permissions';
import {
    loadAccessRoles,
    addAccessRole,
    updateAccessRoles,
    deleteAccessRole,
} from './admin/accessRoles';
import {
    loadCompanies,
    createNewTenantCall,
    loadTenants,
} from './admin/company';
import {
    loadUsers,
    updateUsers,
    deleteUsers,
    createUser,
    loadUsersForProvider,
    updateUsersProvider
} from './user/usersAction';
import {loadAdminLogs} from './admin/adminLog';
import {
    LOGIN_REQUEST,
    USER_DETAIL_UPDATE_REQ,
    ROUTES_REQUESTED,
    ROUTES_DELETE_REQ,
    ROUTES_ADD_REQ,
    PERMISSIONS_LOAD_REQ,
    ACCESS_ROLES_LOAD_REQ,
    ACCESS_ROLE_ADD_REQ,
    ACCESS_ROLES_UPDATE_REQ,
    COMPANY_LOAD_REQ,
    TENANT_CREATE_NEW_REQ,
    TENANT_LOAD_REQ,
    USERS_LOAD_REQ,
    ACCESS_ROLE_REMOVE_REQ,
    PERMISSIONS_ADMIN_LOAD_REQ,
    PERMISSIONS_ADMIN_UPDATE_REQ,
    USERS_UPDATE_REQ,
    ORG_UNITS_LOAD_REQ,
    ORG_UNIT_ADD_REQ,
    ORG_UNIT_HIERARCHY_CHANGED_REQ,
    ORG_UNIT_DELETE_REQ,
    USERS_DELETE_REQ,
    ADMIN_LOGS_REQ,
    PROVIDERS_LOAD_REQ,
    PROVIDER_DELETE_REQ,
    PROVIDER_ADD_REQ,
    PROVIDER_EDIT_REQ,
    PENSION_FUND_GOTO_INPUT,
    PENSION_FUND_PERIOD_LOAD_REQ,
    PENSION_FUND_PERIOD_STATUS_LOAD_REQ,
    PENSION_FUND_DATA_ACCORDION_UPDATE_REQ,
    PENSION_FUND_VALIDATION_DATA_LOAD_REQ,
    PENSION_FUND_VALIDATION_DATA_UPDATE_REQ,
    PENSION_FUND_INPUT_COMPLETE,
    PENSION_FUND_DATA_LOAD_REQ,
    PENSION_FUND_BY_ID_REQ,
    USERS_CREATE_REQ,
    LOGIN_REFRESH_REQUEST, USERS_LOAD_FOR_PROVIDER_REQ, USERS_UPDATE_PROVIDER_REQ, USER_PASSWORD_UPDATE_REQ,
    REQUEST_PASSWORD_RESET_REQ,
    RESET_FORGOTTEN_PASSWORD_REQ,
    CHECK_VERIFICATION_TOKEN_REQ,
    PROVIDERS_ACTIVE_LOAD_REQ, PENSION_FUND_SEARCH_REQ
} from './actionTypes';
import statsSaga from "./statistics/statsSaga";

export default function* rootSaga() {
    yield [
        yield takeLatest(LOGIN_REQUEST, doLoginSaga),
        yield takeLatest(LOGIN_REFRESH_REQUEST, doRefreshLogin),
        yield takeLatest(ROUTES_REQUESTED, requestRoutes),
        yield takeLatest(ROUTES_DELETE_REQ, deleteRouteApi),
        yield takeLatest(ROUTES_ADD_REQ, addRoute),
        yield takeLatest(PERMISSIONS_LOAD_REQ, loadPermissions),
        yield takeLatest(ACCESS_ROLES_LOAD_REQ, loadAccessRoles),
        yield takeLatest(ACCESS_ROLE_ADD_REQ, addAccessRole),
        yield takeLatest(ACCESS_ROLES_UPDATE_REQ, updateAccessRoles),
        yield takeLatest(ACCESS_ROLE_REMOVE_REQ, deleteAccessRole),
        yield takeLatest(COMPANY_LOAD_REQ, loadCompanies),
        yield takeLatest(TENANT_CREATE_NEW_REQ, createNewTenantCall),
        yield takeLatest(TENANT_LOAD_REQ, loadTenants),
        yield takeLatest(PERMISSIONS_ADMIN_LOAD_REQ, loadPermissionsForAdmin),
        yield takeLatest(PERMISSIONS_ADMIN_UPDATE_REQ, updateAdminPermissions),
        yield takeLatest(USER_DETAIL_UPDATE_REQ, updateUserDetails),
        yield takeLatest(USERS_LOAD_REQ, loadUsers),
        yield takeLatest(USERS_UPDATE_REQ, updateUsers),
        yield takeLatest(USERS_DELETE_REQ, deleteUsers),
        yield takeLatest(ORG_UNITS_LOAD_REQ, loadOrgUnits),
        yield takeLatest(ORG_UNIT_ADD_REQ, addOrgUnit),
        yield takeLatest(ORG_UNIT_HIERARCHY_CHANGED_REQ, editOrgUnitHierarchy),
        yield takeLatest(ORG_UNIT_DELETE_REQ, deleteOrgUnit),
        yield takeLatest(ADMIN_LOGS_REQ, loadAdminLogs),
        yield takeLatest(PROVIDERS_LOAD_REQ, loadAllProviders),
        yield takeLatest(PROVIDERS_ACTIVE_LOAD_REQ, loadActiveProviders),
        yield takeLatest(PROVIDER_DELETE_REQ, deleteProvider),
        yield takeLatest(PROVIDER_ADD_REQ, addProvider),
        yield takeLatest(PROVIDER_EDIT_REQ, editProvider),
        yield takeLatest(PENSION_FUND_GOTO_INPUT, forwardToInputPensionData),
        yield takeLatest(PENSION_FUND_PERIOD_LOAD_REQ, loadPensionPeriods),
        yield takeLatest(
            PENSION_FUND_VALIDATION_DATA_LOAD_REQ,
            loadPensionFundValidations
        ),
        yield takeLatest(
            PENSION_FUND_VALIDATION_DATA_UPDATE_REQ,
            updatePensionFundValidations
        ),
        yield takeLatest(
            PENSION_FUND_DATA_ACCORDION_UPDATE_REQ,
            updatePensionFundDataSection
        ),
        yield takeLatest(PENSION_FUND_INPUT_COMPLETE, completePensionFundData),
        yield takeLatest(PENSION_FUND_DATA_LOAD_REQ, loadPensionFundData),
        yield takeLatest(PENSION_FUND_BY_ID_REQ, loadPensionFundById),
        yield takeLatest(USERS_CREATE_REQ, createUser),
        yield takeLatest(USERS_LOAD_FOR_PROVIDER_REQ, loadUsersForProvider),
        yield takeLatest(USERS_UPDATE_PROVIDER_REQ, updateUsersProvider),
        yield takeLatest(USER_PASSWORD_UPDATE_REQ, updateUserPassword),
        yield takeLatest(REQUEST_PASSWORD_RESET_REQ, doRequestPasswordReset),
        yield takeLatest(RESET_FORGOTTEN_PASSWORD_REQ, doResetForgottenPassword),
        yield takeLatest(CHECK_VERIFICATION_TOKEN_REQ, doCheckVerificationToken),
        yield takeLatest(PENSION_FUND_PERIOD_STATUS_LOAD_REQ, loadPensionPeriodsStatus),
        yield takeLatest(PENSION_FUND_SEARCH_REQ, pensionFundSearchMask),
        statsSaga()
    ];
}
