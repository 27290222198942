import {
    COMPANY_LOAD_REQ,
    COMPANY_LOAD_RESP,
    TENANT_LOAD_REQ,
    TENANT_LOAD_RESP,
    TENANT_CREATE_NEW_REQ,
    TENANT_CREATE_NEW_RESP
} from '../actionTypes';

import {put, call} from 'redux-saga/effects';
import {apiCall} from '../api';

export function* loadCompanies() {
    try {
        let data = yield call(apiCall, '/tenant/getAllCompanies');
        yield put(responseLoadCompanies(data.companies));
    } catch (e) {
        console.log('Loading of companies failed');
        yield put(responseLoadCompanies([]));
    }
}

export function* loadTenants() {
    try {
        let data = yield call(apiCall, '/tenant/getAllTenants');
        yield put(responseLoadTenants(data.tenants));
    } catch (e) {
        console.log('Loading of tenants failed');
        yield put(responseLoadTenants([]));
    }
}

export function* createNewTenantCall({newTenant}) {
    try {
        let data = yield call(apiCall, '/tenant/createNewTenant', {newTenant});
        yield put(responseCreateNewTenant(data.msg));
    } catch (e) {
        yield put(responseCreateNewTenant([]));
    }
}

export const requestLoadCompanies = () => ({
    type: COMPANY_LOAD_REQ,
});

export const requestLoadTenants = () => ({
    type: TENANT_LOAD_REQ,
});

export const requestCreateNewTenant = (newTenant) => ({
    type: TENANT_CREATE_NEW_REQ,
    newTenant: newTenant
});

export const responseCreateNewTenant = (msg) => ({
    type: TENANT_CREATE_NEW_RESP,
    msg: msg
});

export const responseLoadCompanies = (companies) => ({
    type: COMPANY_LOAD_RESP,
    companies: companies
});

export const responseLoadTenants = (tenants) => ({
    type: TENANT_LOAD_RESP,
    tenants: tenants
});
