import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGGED_OUT,
    LOGIN_FAILED,
    INITIALIZE_ROUTES,
    USER_CONTEXT_ADD,
    USER_CONTEXT_REMOVED,
    LOGIN_REFRESH_REQUEST,
    REQUEST_PASSWORD_RESET_REQ,
    REQUEST_PASSWORD_RESET_FAILED,
    RESET_FORGOTTEN_PASSWORD_REQ,
    CHECK_VERIFICATION_TOKEN_REQ,
    CHECK_VERIFICATION_TOKEN_FAILED,
    CHECK_VERIFICATION_TOKEN_SUCCESS
} from './actionTypes';

import { put, call } from  'redux-saga/effects';
import {apiLoginCall} from './api';

export function* doLoginSaga({username, password}){
    try {
        let data = yield call(apiLoginCall, '/app/login', {username: username, password: password});
            if (data.routes && data.context) {
                yield put(initializeRoutes(data.routes));
                yield put(addUserToContext(data));
                yield put(loginSuccess());
            } else {
                yield put(loginFailed(data.msg));
            }
    } catch (e) {
        yield put(loginFailed({msg: 'server.maintenance', type: 'error'}));
    }
}

export function* doRefreshLogin({token, refreshOnly}){
    try {
        let data = yield call(apiLoginCall, '/app/refreshLogin', {token});
        if (!refreshOnly) {
            if (data.routes && data.context) {
                yield put(initializeRoutes(data.routes));
                yield put(addUserToContext(data));
                yield put(loginSuccess());
            } else {
                yield put(loginFailed(data.msg));
            }
        }
    } catch (e) {
        yield put(loginFailed({msg: 'server.maintenance', type: 'error'}));
    }
}

export function* doRequestPasswordReset({ email }) {
    try {
        yield call(apiLoginCall, '/app/requestPasswordReset', { email });
    } catch (e) {
        yield put(requestPasswordResetFailed());
    }
}

export function* doResetForgottenPassword({ token, password, confirmPassword }) {
    try {
        yield call(apiLoginCall, '/app/resetPassword', { token, password, confirmPassword });
    } catch (e) {
        yield put(requestPasswordResetFailed());
    }
}

export function* doCheckVerificationToken({ token }) {
    try {
        let data = yield call(apiLoginCall, '/app/checkVerificationToken', { token: token });
        if (data.verified) {
            yield put(checkVerificationTokenSuccess());
        } else {
            yield put(checkVerificationTokenFailed(data.msg));
        }
    } catch (e) {
        yield put(loginFailed({ msg: 'server.maintenance', type: 'error' }));
    }
}

export const addUserToContext = (context) => ({
    type: USER_CONTEXT_ADD,
    context: context
});
export const removeUserFromContext = () => ({
    type: USER_CONTEXT_REMOVED,
    context: null
});

export const loginRequest = (username, password) => ({
    type: LOGIN_REQUEST,
    username,
    password
});

export const requestPasswordReset = (email) => ({
    type: REQUEST_PASSWORD_RESET_REQ,
    email
});

export const checkVerificationToken = (token) => ({
    type: CHECK_VERIFICATION_TOKEN_REQ,
    token
});

export const resetForgottenPassword = (token, password, confirmPassword) => ({
    type: RESET_FORGOTTEN_PASSWORD_REQ,
    token,
    password,
    confirmPassword
});

export const refreshLoginRequest = (token, refreshOnly = true) => ({
    type: LOGIN_REFRESH_REQUEST,
    token,
    refreshOnly
});

export const requestPasswordResetFailed = () => ({
    type: REQUEST_PASSWORD_RESET_FAILED,
});

export const checkVerificationTokenSuccess = () => ({
    type: CHECK_VERIFICATION_TOKEN_SUCCESS,
});

export const checkVerificationTokenFailed = () => ({
    type: CHECK_VERIFICATION_TOKEN_FAILED,
});

export const loginSuccess = () => ({
    type: LOGIN_SUCCESS,
});

export const initializeRoutes = (routes) => ({
    type: INITIALIZE_ROUTES,
    routes: routes
});

export const loginFailed = msg => ({
    type: LOGIN_FAILED,
    msg: msg,
});

export const logout = () => ({
    type: LOGGED_OUT,
});