import {
    PROVIDER_ADD_REQ,
    PROVIDER_ADD_RESP,
    PROVIDERS_LOAD_REQ,
    PROVIDERS_LOAD_RESP,
    PROVIDERS_ROW_EXPANDED,
    PROVIDER_DELETE_REQ,
    PROVIDER_EDIT_REQ, PROVIDERS_ACTIVE_LOAD_RESP, PROVIDERS_ACTIVE_LOAD_REQ
} from '../actionTypes';
import {apiCall} from '../api'
import {put, call, spawn} from 'redux-saga/effects';
import {actions as notifActions} from 'redux-notifications';
import {createNotification} from "../notificationProvider";

const {notifSend} = notifActions;

export const requestProviders = () => ({
    type: PROVIDERS_LOAD_REQ
});

export const requestActiveProviders = () => ({
    type: PROVIDERS_ACTIVE_LOAD_REQ
});

export const responseLoadProviders = (providers) => ({
    type: PROVIDERS_LOAD_RESP,
    providers: providers
});

export const responseLoadActiveProviders = (activeProviders) => ({
    type: PROVIDERS_ACTIVE_LOAD_RESP,
    activeProviders: activeProviders
});

export const expandedProviderIds = (expandedProviderIds) => ({
    type: PROVIDERS_ROW_EXPANDED,
    expandedProviderIds: expandedProviderIds
});

export const responseAddProvider = (provider) => ({
    type: PROVIDER_ADD_RESP,
    provider: provider
});

export const requestDeleteProvider = (providerId) => ({
    type: PROVIDER_DELETE_REQ,
    providerId: providerId
});

export const requestEditProvider = (modifiedProvider) => ({
    type: PROVIDER_EDIT_REQ,
    modifiedProvider: modifiedProvider
});

export const requestAddProvider = (provider) => ({
    type: PROVIDER_ADD_REQ,
    newProvider: provider
});

export function* loadAllProviders() {
    try {
        let data = yield call(apiCall, '/provider/loadAllProviders');
        yield put(responseLoadProviders(data.providers));
    } catch (e) {
        console.log('Loading of providers failed');
        yield put(responseLoadProviders([]));
    }
}

export function* loadActiveProviders() {
    try {
        let data = yield call(apiCall, '/provider/loadActiveProviders');
        yield put(responseLoadActiveProviders(data.providers));
    } catch (e) {
        console.log('Loading of providers failed');
        yield put(responseLoadActiveProviders([]));
    }
}


export function* addProvider({newProvider}) {
    try {
        let data = yield call(apiCall, '/provider/addProvider', {newProvider});
        yield put(responseAddProvider(data.provider));
        yield spawn(loadAllProviders);
        yield put(notifSend(createNotification(data.msg)));
    } catch (e) {
        yield put(notifSend(createNotification({})));
    }
}

export function* editProvider({modifiedProvider}) {
    try {
        let data = yield call(apiCall, '/provider/editProvider', {modifiedProvider});
        yield spawn(loadAllProviders);
        yield put(notifSend(createNotification(data.msg)));
    } catch (e) {
        yield put(notifSend(createNotification({})));
    }
}

export function* deleteProvider({providerId}) {
    try {
        let data = yield call(apiCall, '/provider/deleteProvider', {providerId});
        yield spawn(loadAllProviders);
        yield put(notifSend(createNotification(data.msg)));
    } catch (e) {
        yield put(notifSend(createNotification({})));
    }
}


