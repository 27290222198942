import {
    PENSION_FUND_CHANGE_STEP,
    PENSION_FUND_CHANGE_VALIDATION,
    PENSION_FUND_DATA_LOAD_RESP,
    PENSION_FUND_DATA_LOAD_REQ,
    PENSION_FUND_DATA_ACCORDION_UPDATE_REQ,
    PENSION_FUND_EXPAND_ACCORDION,
    PENSION_FUND_PERIOD_LOAD_RESP,
    PENSION_FUND_PERIOD_STATUS_LOAD_RESP,
    PENSION_FUND_RESET_ACCORDION,
    PENSION_FUND_VALIDATION_DATA_LOAD_REQ,
    PENSION_FUND_VALIDATION_DATA_LOAD_RESP,
    PENSION_FUND_GOTO_INPUT,
    PENSION_FUND_PROVIDER_DATA_LOAD_RESP,
    PENSION_FUND_BY_ID_REQ,
    PENSION_FUND_BY_ID_RESP,
    PENSION_FUND_PERIOD_STATUS_LOAD_REQ,
    PENSION_FUND_SEARCH_REQ,
    PENSION_FUND_SEARCH_RESP,
} from "../../actions/actionTypes";
let initialState = {
    pensionFundData: {},
    pensionFundDataList: [],
    pensionFund: {},
    pensionFundDataProvider: {},
    pensionFundPeriods: [],
    pensionFundDataValidationLoaded:false,
    activeStep: 0,
    expandedAccordion: '',
    searchCriteria: {},
    searchResultProviderList: [],
    pensionFundValidationData: {},
    pensionFundPeriodsWithStatus: [],
    loadingPFPeriodWithStatus: false,
    loadingNext: false,
    loadingPensionFund: false,
    loadingAccordion: false,
    loadingPensionFundData: false,
    loadingPensionFundDataList: false,
    pensionFundPeriodNameById: {},
    pensionFundPeriodIdByName: {}
};

export const pensionFundReducer = (state = initialState, action) => {
    switch (action.type) {
        case PENSION_FUND_PROVIDER_DATA_LOAD_RESP:
            if (action.section) {
                return {
                    ...state,
                    loadingNext: false,
                    loadingAccordion: false,
                    pensionFundDataProvider: {...state.pensionFundDataProvider, [action.section]: action.pensionFundDataProvider[action.section]},
                };
            } else {
                return {
                    ...state,
                    loadingNext: false,
                    loadingAccordion: false,
                    pensionFundDataProvider: action.pensionFundDataProvider,
                };
            }
        case PENSION_FUND_GOTO_INPUT:
            return {
                ...state,
                loadingNext: true,
            };
        case PENSION_FUND_SEARCH_REQ:
            return {
                ...state,
                searchCriteria: action.searchCriteria,
            };
        case PENSION_FUND_SEARCH_RESP:
            return {
                ...state,
                searchResultProviderList: action.providerList,
            };
        case PENSION_FUND_DATA_LOAD_REQ:
            return {
                ...state,
                loadingPensionFundDataList: true,
            };
        case PENSION_FUND_DATA_LOAD_RESP:
            return {
                ...state,
                pensionFundDataList: action.pensionFundData,
                loadingPensionFundDataList: false,
            };
        case PENSION_FUND_DATA_ACCORDION_UPDATE_REQ:
            return {
                ...state,
                loadingAccordion: true,
            };
        case PENSION_FUND_BY_ID_REQ:
            return {
                ...state,
                loadingPensionFundData: true,
            };
        case PENSION_FUND_BY_ID_RESP:
            return {
                ...state,
                loadingPensionFundData: false,
                pensionFundData: action.pensionFundData
            };
        case PENSION_FUND_PERIOD_LOAD_RESP:
            const resultById = action.pensionFundPeriods.reduce(function(map, obj) {
                map[obj._id] = obj.name;
                return map;
            }, {});
            const resultByName = action.pensionFundPeriods.reduce(function (map, obj) {
                map[obj.name] = obj._id;
                return map;
            }, {});
            return {
                ...state,
                pensionFundPeriods: action.pensionFundPeriods,
                pensionFundPeriodIdByName: resultByName,
                pensionFundPeriodNameById: resultById
            };
        case PENSION_FUND_PERIOD_STATUS_LOAD_REQ:
            return {
                ...state,
                loadingPFPeriodWithStatus: true,
                pensionFundPeriodsWithStatus: []
            };
        case PENSION_FUND_PERIOD_STATUS_LOAD_RESP:
            const periodsById = action.pensionFundPeriodsWithStatus.reduce(function(map, obj) {
                map[obj._id] = obj.name;
                return map;
            }, {});
            const periodsByName = action.pensionFundPeriodsWithStatus.reduce(function (map, obj) {
                map[obj.name] = obj._id;
                return map;
            }, {});
            return {
                ...state,
                loadingPFPeriodWithStatus: false,
                pensionFundPeriodNameById: periodsById,
                pensionFundPeriodIdByName: periodsByName,
                pensionFundPeriodsWithStatus: action.pensionFundPeriodsWithStatus,
            };
        case PENSION_FUND_CHANGE_STEP:
            return {
                ...state,
                activeStep: action.stepId
            };
        case PENSION_FUND_RESET_ACCORDION:
            return {
                ...initialState
            };
        case PENSION_FUND_EXPAND_ACCORDION:
            return {
                ...state,
                expandedAccordion: action.accordion
            };
        case PENSION_FUND_VALIDATION_DATA_LOAD_RESP:
            return {
                ...state,
                pensionFundValidationData: action.pensionFundValidationData,
                pensionFundDataValidationLoaded: true
            };
        case PENSION_FUND_VALIDATION_DATA_LOAD_REQ:
            return {
                ...state,
                pensionFundDataValidationLoaded: false
            };
        case PENSION_FUND_CHANGE_VALIDATION:
            const section = action.section;
            const value = action.value;
            const field = action.field;
            const data = {...state.pensionFundValidationData};
            const currentVal = data[section][value][field];
            data[section][value][field] = !currentVal;
            return {
                ...state,
                pensionFundValidationData: data
            };
        default:
            return state;
    }
};
