import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {logout} from "../../actions";

const CountdownLogout = () => {
    const [timeLeft, setTimeLeft] = useState(120);
    const dispatch = useDispatch();

    useEffect(()=> {
        setTimeout(() => {
            const newTime = timeLeft - 1;
            setTimeLeft(newTime);
        }, 1000);
    });

    if (timeLeft === 0) {
        dispatch(logout());
    }

    return (
        <div>
            Your session will expire in {timeLeft}. Do you want to exit now?
        </div>
    )
};

export default CountdownLogout;