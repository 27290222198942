import {
    REPORT_SET_FIXED_PROVIDER_LIST,
    REPORT_CHANGE_REPORT_PERIOD,
    REPORT_CHANGE_DISPLAYED_ROWS,
    REPORT_CHANGE_CONVERSIONRATE_RANKING,
    REPORT_SET_ANONYMISE_PROVIDERS
} from "../../actions/actionTypes";
let initialState = {
    reportPeriod: 2019,
    displayedRows: null,
    conversionRateRanking: 'desc',
    anonymiseProviders: false,
    fixedProviderList: {
        providerList: [],
        basisReport: ''
    }
};

export const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_SET_FIXED_PROVIDER_LIST:
            return {
                ...state,
                fixedProviderList: action.fixedProviderList
            };
        case REPORT_CHANGE_REPORT_PERIOD:
            return {
                ...state,
                reportPeriod: action.reportPeriod
            };
        case REPORT_CHANGE_DISPLAYED_ROWS:
            return {
                ...state,
                displayedRows: +action.displayedRows
            };
        case REPORT_CHANGE_CONVERSIONRATE_RANKING:
            return {
                ...state,
                conversionRateRanking: action.conversionRateRanking
            }; 
        case REPORT_SET_ANONYMISE_PROVIDERS:
            return {
                ...state,
                anonymiseProviders: action.anonymise
            }
        default:
            return state;
    }
};
