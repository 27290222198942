import {STATISTICS_PF_DATA_RESP} from "../../actions/actionTypes";

const initialState = {
  pensionDataStatsByPeriods: []
};

export const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATISTICS_PF_DATA_RESP:
      return {
        ...state,
        pensionDataStatsByPeriods: action.data
      };
    default:
      return state;
  }
};

export default statisticsReducer;
