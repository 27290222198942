import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGGED_OUT,
    LOGIN_FAILED,
    CHECK_VERIFICATION_TOKEN_SUCCESS,
    CHECK_VERIFICATION_TOKEN_FAILED
} from '../actions/actionTypes';

const proceedToLogin = (state = {
    logged: false,
    logging: false,
    msg: '',
    resetTokenVerified: false
}, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                logged: false,
                logging: true,
            };
        case LOGIN_SUCCESS:
            return {
                logged: true,
                logging: false,
            };
        case LOGIN_FAILED:
            return {
                msg: action.msg,
                logged: false,
                logging: false,
            };
        case LOGGED_OUT:
            sessionStorage.clear();
            localStorage.clear();
            return {
                logged: false,
                logging: false
            };
        case CHECK_VERIFICATION_TOKEN_SUCCESS:
            return {
                resetTokenVerified: true,
            };
        case CHECK_VERIFICATION_TOKEN_FAILED:
            return {
                resetTokenVerified: false,
            };
        default:
            return state;
    }
};

export default proceedToLogin;
