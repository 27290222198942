import axios from 'axios';
import {createNotification} from '../../actions/notificationProvider';
import {actions as notifActions} from 'redux-notifications';
import {logout} from "../../actions";

const {notifSend} = notifActions;

export default {
    setupInterceptors: (history, store) => {
        axios.interceptors.request.use(
            function (config) {
                config.headers['Cache-Control'] =
                    'no-cache,no-store,must-revalidate,max-age=-1,private';
                config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
                return config;
            },
            function (err) {
                sessionStorage.clear(); //why??
                return Promise.reject(err);
            }
        );
        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                if (response.headers.authtoken) {
                    sessionStorage.setItem('token', response.headers.authtoken);
                }
                return response;
            },
            function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        history.push('/admin/error');
                    } else if (error.response.status === 405) {
                        sessionStorage.clear();
                        localStorage.clear();
                        store.dispatch(
                            logout()
                        );
                    } else {
                        store.dispatch(
                            notifSend(createNotification({ msg: 'common.error', type: 'error' }))
                        );
                    }

                }
                return Promise.reject(error);
            }
        );
    },
};
