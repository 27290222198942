import {
  INITIALIZE_ROUTES
} from '../actions/actionTypes';

const routesReducer = (state = {routes: []}, action) => {
  switch (action.type) {
    case INITIALIZE_ROUTES:
      return {
        routes: action.routes,
      };
    default:
      return state;
  }
};

export default routesReducer;
