import axios from 'axios';

export default {
    post: (url, data, config) => {
        const domain = process.env.NODE_ENV === 'development' ? 'http://localhost:3333' : '';
        return axios.post(domain + url, data, config);
    },
    get: (url, config) => {
        const domain = process.env.NODE_ENV === 'development' ? 'http://localhost:3333' : '';
        return axios.get(domain + url, config);
    }
};
