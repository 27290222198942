import {
    ACCESS_ROLES_LOAD_REQ,
    ACCESS_ROLES_LOAD_RESP,
    ACCESS_ROLE_ADD_REQ,
    ACCESS_ROLES_UPDATE_REQ,
    ACCESS_ROLES_UPDATE_RESP,
    ACCESS_ROLES_REPLACE_PERMISSION,
    ACCESS_ROLE_ADD_RESP,
    ACCESS_ROLE_REMOVE_REQ,
    ACCESS_ROLE_REMOVE_RESP
} from '../actionTypes';
import {put, call} from 'redux-saga/effects';
import {apiCall} from '../api';
import {createNotification} from '../notificationProvider';
import {actions as notifActions } from 'redux-notifications';
const { notifSend } = notifActions;


export function* loadAccessRoles() {
    try {
        let data = yield call(apiCall, '/accessRole/getAccessRoles');
        yield put(responseAccessRoles(data.accessRoles));
    } catch (e) {
        console.log('Loading of access roles failed');
        yield put(responseAccessRoles([]));
    }
}

export function* addAccessRole({newAccessRole}) {
    try {
        let data = yield call(apiCall, '/accessRole/addAccessRole', {newAccessRole});
        yield put(addNewAccessRoleResp(data.msg));
        yield put(requestAccessRoles())
    } catch (e) {
        yield put(addNewAccessRoleResp('Server under maintenance'));
    }
}

export function* deleteAccessRole({accessRoleId}) {
    try {
        let data = yield call(apiCall, '/accessRole/deleteAccessRole', {accessRoleId});
        yield put(responseAccessRoleDelete(data.msg));
        yield put(requestAccessRoles());
        yield put(notifSend(createNotification(data.msg)));
    } catch (e) {
        yield put(responseAccessRoleDelete({}));
    }
}


export function* updateAccessRoles({accessRolesToUpdate}) {
    try {
        let data = yield call(apiCall, '/accessRole/updateAccessRoles', {accessRolesToUpdate});
        yield put(responseAccessRolesUpdate(data.msg));
        yield put(requestAccessRoles());
        yield put(notifSend(createNotification(data.msg)));
    } catch (e) {
        yield put(responseAccessRolesUpdate('Server under maintenance'));
    }
}

export const requestAccessRoles = () => ({
    type: ACCESS_ROLES_LOAD_REQ,
});

export const requestAccessRolesUpdate = (accessRoles) => ({
    type: ACCESS_ROLES_UPDATE_REQ,
    accessRolesToUpdate: accessRoles
});

export const requestAccessRoleDelete = (accessRoleId) => ({
    type: ACCESS_ROLE_REMOVE_REQ,
    accessRoleId: accessRoleId
});

export const responseAccessRoleDelete = (msg) => ({
    type: ACCESS_ROLE_REMOVE_RESP,
    msg: msg
});

export const responseAccessRolesUpdate = (msg) => ({
    type: ACCESS_ROLES_UPDATE_RESP,
    msg: msg
});

export const addNewAccessRoleReq = (newAccessRole) => ({
    type: ACCESS_ROLE_ADD_REQ,
    newAccessRole: newAccessRole
});

export const replaceAccessRolePermissions = (newPermissions, accessRoleId) => ({
    type: ACCESS_ROLES_REPLACE_PERMISSION,
    accessRoleId,
    newPermissions
});

export const addNewAccessRoleResp = (msg) => ({
    type: ACCESS_ROLE_ADD_RESP,
    msg: msg
});

export const responseAccessRoles = (accessRoles) => ({
    type: ACCESS_ROLES_LOAD_RESP,
    accessRoles: accessRoles
});
