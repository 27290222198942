import {
  ORG_UNITS_LOAD_REQ,
  ORG_UNITS_LOAD_RESP,
  ORG_UNIT_ADD_REQ,
  ORG_UNIT_TREE_SELECTED,
  ORG_UNIT_TREE_HIERARCHY_CHANGED,
  ORG_UNITS_EXPANDED,
  ORG_UNIT_ADD_RESP,
} from '../../actions/actionTypes';

let initialState = {
  orgUnits: [],
  orgUnitsLoading: false,
  expandedRowIds: [],
  addingOrgUnit: false,
  flatStructure: [],
  orgUnitsTreeDropdown: [],
  selectedOrgUnit: null,
};

export const orgUnitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORG_UNITS_LOAD_REQ: {
      return {
        ...state,
        orgUnits: [],
        orgUnitsLoading: true,
        expandedRowIds: [],
      };
    }
    case ORG_UNITS_LOAD_RESP: {
      let expandedRowIds = action.orgUnits.map(it => it._id);
      let orgUnits = action.orgUnits;
      let orgUnitId = state.selectedOrgUnit;
      let structure = createTreeStructure(orgUnits, orgUnitId);
      return {
        ...state,
        expandedRowIds: expandedRowIds,
        orgUnitsLoading: false,
        orgUnits: action.orgUnits,
        orgUnitsTreeDropdown: structure,
        flatStructure: orgUnits,
        orgUnitsTreeHierarchy: structure,
        hierarchyChanged: false,
      };
    }
    case ORG_UNITS_EXPANDED: {
      return {
        ...state,
        expandedRowIds: action.expandedOrgUnitIds,
        orgUnitsLoading: false,
        orgUnits: state.orgUnits,
      };
    }
    case ORG_UNIT_ADD_REQ: {
      return {
        ...state,
        addingOrgUnit: true,
      };
    }
    case ORG_UNIT_ADD_RESP: {
      return {
        ...state,
        addingOrgUnit: false,
      };
    }
    case ORG_UNIT_TREE_SELECTED: {
      return {
        ...state,
        orgUnitsTreeDropdown: createTreeStructure(
            state.flatStructure,
            action.selectedOrgUnit
        ),
        selectedOrgUnit: action.selectedOrgUnit,
        flatStructure: [...state.flatStructure],
      };
    }
    case ORG_UNIT_TREE_HIERARCHY_CHANGED: {
      let newHierarchy = [];
      for (const obj of action.newHierarchy) {
        let newObj = { ...obj };
        copyHierarchy(newObj);
        newHierarchy.push(newObj);
      }
      return {
        ...state,
        orgUnitsTreeHierarchy: newHierarchy,
        hierarchyChanged: true,
      };
    }
    default:
      return state;
  }
};

function copyHierarchy(obj) {
  if (obj.children) {
    let newChildren = [];
    for (let obj1 of obj.children) {
      let newObj = { ...obj1 };
      newChildren.push(newObj);
      copyHierarchy(newObj);
    }
    obj.children = newChildren;
  }
}

function appendChilds(child, orgUnitsMap, orgUnitId) {
  let parentChilds = orgUnitsMap.filter(
    orgUnit => orgUnit.parent === child._id
  );
  if (parentChilds.length > 0) {
    let childs = [];
    for (const subChild of parentChilds) {
      let newChild = {
        label: subChild.name,
        title: subChild.name,
        subtitle: subChild.weight,
        checked: orgUnitId === subChild._id,
        value: subChild._id,
        expanded: true,
        ...subChild,
      };
      appendChilds(newChild, orgUnitsMap, orgUnitId);
      childs.push(newChild);
    }
    child.children = childs;
  }
}

function createTreeStructure(orgUnits, orgUnitId) {
  let structure = [];
  let root = orgUnits.filter(orgunit => !orgunit.hasOwnProperty('parent'));
  let childs = orgUnits.filter(orgunit => orgunit.hasOwnProperty('parent'));
  for (const parent of root) {
    let newParent = {
      label: parent.name,
      title: parent.name,
      value: parent._id,
      subtitle: parent.weight,
      expanded: true,
      checked: orgUnitId === parent._id,
      ...parent,
    };
    appendChilds(newParent, childs, orgUnitId);
    structure.push(newParent);
  }
  return structure;
}
