import React from 'react';
import {Card, CardHeader, CardTitle, Col, Row} from "reactstrap";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        console.log(error);
        return {hasError: true};
    }

    componentDidCatch(error, info) {
        // logErrorToMyService(error, info);
        console.log(error);
        console.log(info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        Something went wrong
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                </div>);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
