import {
    USER_DETAIL_UPDATE_REQ,
    USER_DETAIL_UPDATE_RESP,
    USER_PASSWORD_UPDATE_REQ,
} from './actionTypes';
import {addUserToContext} from './index';
import {apiCall} from './api'
import { put, call } from  'redux-saga/effects';
import {actions as notifActions } from 'redux-notifications';
import {createNotification} from './notificationProvider';
const { notifSend } = notifActions;


export const userDetailedUpdated = () => ({
    type: USER_DETAIL_UPDATE_RESP
});

export const userPasswordUpdateReq = (userId, oldPwd, newPwd, repeatPwd) => ({
    type: USER_PASSWORD_UPDATE_REQ,
    userId,
    oldPwd,
    newPwd,
    repeatPwd
});

export const updateUserDetailRequest = (userDetails) => ({
    type: USER_DETAIL_UPDATE_REQ,
    userDetails
});

export function* updateUserDetails({userDetails}) {
    try {
        const result = yield call(apiCall, '/user/updateUserDetails', userDetails);
        if (result.user) {
            yield put(addUserToContext({context: result.user}));
            yield put(userDetailedUpdated());
            yield put(notifSend(createNotification(result.msg)));
        }
    } catch(error) {
        yield put(notifSend(createNotification({type: 'error', msg: 'Server could not be reached.'})));
    }
}

export function* updateUserPassword({userId, oldPwd, newPwd, repeatPwd}) {
    try {
        const resp = yield call(apiCall,'/user/updateUserPassword', {userId, oldPwd, newPwd, repeatPwd});
        yield put(notifSend(createNotification(resp.msg)));
    } catch(error) {
        yield put(notifSend(createNotification({type: 'error', msg: 'Server could not be reached.'})));
    }
}
