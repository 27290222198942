import ApiWrapper from "../components/Network/apiWrapper";
let address = process.env.npm_package_config_address || '/api';

export const apiCall = (path, params) => {
    return ApiWrapper.post(address + path, {
        ...params
    }).then(resp =>  resp.data
    ).catch(err => {throw err}
    );
};

export const apiGet = (path, params) => {
    return ApiWrapper.get(address + path, {
        ...params
    }).then(resp => resp
    ).catch(err => { throw err }
    );
};

export const apiLoginCall = (path, params) => {
    return ApiWrapper.post(path, {
        ...params
    }).then(resp =>  resp.data
    ).catch(err => {throw err}
    );
};