import {
    ROUTES_RECEIVED,
    ROUTES_REQUESTED,
    ROUTES_REQUEST_FAILED,
    ROUTES_DELETE_REQ,
    ROUTES_DELETE_RESP,
    ROUTES_ADD_REQ,
    ROUTES_ADD_RESP,
    ROUTES_ADD_NEW_ROUTE
} from '../actionTypes';

import {put, call} from 'redux-saga/effects';
import {apiCall} from '../api';

export function* requestRoutes() {
    try {
        let data = yield call(apiCall, '/getRoutes');
        if (!data.error) {
            yield put(routesReceived(data.routes));
        } else {
            yield put(routesRequestFailed('Server under maintenance'));
        }
    } catch (e) {
        yield put(routesRequestFailed('Server under maintenance'));
    }
};


export function* deleteRouteApi({routeId}) {
    try {
        let data = yield call(apiCall, '/deleteRoute', {routeId});
        if (!data.error) {
            yield put(routeDeleted(routeId ,data.msg));
            // yield put(routesRequested());
        } else {
            yield put(routeDeleted(routeId, null,'Server under maintenance'));
        }
    } catch (e) {
        yield put(routeDeleted(routeId, null,'Server under maintenance'));
    }
};

export function* addRoute({route}) {
    try {
        let data = yield call(apiCall, '/addRoute', {route});
        yield put(addNewRouteResponse({...data}));
        if (data.newRoute) {
            yield put(addNewRouteToRoutes({newRoute: data.newRoute}));
        }
    } catch (e) {
        yield put(addNewRouteResponse({error: 'Server under maintenance'}));
    }
};

export const routesRequested = () => ({
    type: ROUTES_REQUESTED
});

export const addNewRouteRequest = (route) => ({
    type: ROUTES_ADD_REQ,
    route: route
});

export const addNewRouteToRoutes = ({newRoute}) => ({
    type: ROUTES_ADD_NEW_ROUTE,
    newRoute: newRoute
});

export const addNewRouteResponse = ({error, msg}) => ({
    type: ROUTES_ADD_RESP,
    msg: msg,
    error: error
});

export const deleteRoute = (routeId) => ({
    type: ROUTES_DELETE_REQ,
    routeId: routeId
});

export const routeDeleted = (routeId, msg) => ({
    type: ROUTES_DELETE_RESP,
    msg:msg,
    routeId: routeId
});

export const routesRequestFailed = (error) => ({
    type: ROUTES_REQUEST_FAILED,
    error: error
});

export const routesReceived = (routes) => ({
    type: ROUTES_RECEIVED,
    routes: routes
});
