import {
  COMPANY_LOAD_REQ,
  COMPANY_LOAD_RESP,
  PERMISSIONS_ADMIN_UPDATE,
  TENANT_CREATE_NEW_REQ,
  TENANT_CREATE_NEW_RESP,
  TENANT_LOAD_REQ,
  TENANT_LOAD_RESP,
} from '../../actions/actionTypes';

let initialStateAccessRoles = { companies: [], companiesLoading: false };

export const newTenantReducer = (state = initialStateAccessRoles, action) => {
  switch (action.type) {
    case COMPANY_LOAD_REQ:
      return {
        companies: [],
        companiesLoading: true,
      };
    case COMPANY_LOAD_RESP:
      return {
        companies: action.companies,
        companiesLoading: true,
      };
    default:
      return state;
  }
};

let initialStateTenants = {
  tenants: [],
  tenantsById: new Map([]),
  tenantsLoading: false,
};
export const loadTenantsReducer = (state = initialStateTenants, action) => {
  switch (action.type) {
    case TENANT_LOAD_REQ:
      return {
        tenantsById: new Map([]),
        tenants: [],
        tenantsLoading: true,
      };
    case TENANT_LOAD_RESP:
      let tenantsById = new Map(
        action.tenants.map(tenant => [tenant._id, tenant])
      );
      return {
        tenantsById: tenantsById,
        tenants: action.tenants,
        tenantsLoading: true,
      };
    case PERMISSIONS_ADMIN_UPDATE:
      let tenantId = action.tenantId;
      let permissions = action.newPermissions;
      let tenants = state.tenants.slice();
      tenants.forEach(function(tenant) {
        if (tenant._id === tenantId) {
          tenant.permissions = permissions;
          tenant.editMode = true;
        }
      });
      return {
        tenantsById: state.tenantsById,
        tenants: [...tenants],
        tenantsLoading: false,
      };
    default:
      return state;
  }
};

let initialStateCreateNewTenant = { createNewTenantRequested: false };
export const createNewTenantReducer = (
  state = initialStateCreateNewTenant,
  action
) => {
  switch (action.type) {
    case TENANT_CREATE_NEW_REQ:
      return {
        createNewTenantRequested: true,
      };
    case TENANT_CREATE_NEW_RESP:
      return {
        msg: action.msg,
        createNewTenantRequested: false,
      };
    default:
      return state;
  }
};
